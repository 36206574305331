// Imports
// ------
import React from 'react';
import Job from './Job';
import { Row, Column } from '@waffl';
import { useDato } from './data';

// Styles
// ------
import { Jacket, Jobs } from './styles';

// Component
// ------
function Listing() {
	const { nodes } = useDato();

	return (
		<Jacket pad>
			<Row isExpanded>
				<Column small={12} medium={10} pushOnMedium={1} mpad>
					<Jobs>
						{nodes.map(
							({ id, title, position, location, salary, link, date }) => (
								<Job
									key={id}
									title={title}
									position={position}
									posted={date.published}
									location={location}
									salary={salary}
									link={link}
								/>
							)
						)}
					</Jobs>
				</Column>
			</Row>
		</Jacket>
	);
}

export default Listing;
