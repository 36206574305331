// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 2;
	`
);

export const Jobs = styled.div(
	(props) => css`
		display: grid;
		grid-gap: 2.4rem;

		${breakup.large`
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 3.6rem;
		`}
	`
);
