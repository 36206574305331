// Imports
// ------
import React from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

// Styles
// ------
import { Jacket, Title, Content, Part, Label, Answer, Posted } from './styles';

// Component
// ------
const Job = ({ title, position, location, salary, link, posted }) => {
	dayjs.extend(relativeTime);

	// NOTE DayJS
	const postedOn = dayjs(posted).fromNow();

	// NOTE • Link Props
	const linkProps = {
		href: link,
		target: '_blank',
		rel: 'noopener noreferrer',
		className: 'cursor-hover',
		'aria-label': `Go to ${link}`,
	};

	return (
		<Jacket {...linkProps}>
			<Title>{title}</Title>

			<Content>
				<Part data-label='Type'>
					<Label>Type</Label>
					<Answer>{position}</Answer>
				</Part>

				<Part data-label='Type'>
					<Label>Location</Label>
					<Answer>{location}</Answer>
				</Part>

				<Part data-label='Type'>
					<Label>Salary</Label>
					<Answer>{salary}</Answer>
				</Part>

				<Posted>{postedOn}</Posted>
			</Content>
		</Jacket>
	);
};

export default React.memo(Job);
