// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, H5, H6, P } from '@tackl';

// Exports
// ------
export const Jacket = styled.a(
	(props) => css`
		position: relative;

		display: flex;
		flex-flow: column;
		align-items: flex-start;
		justify-content: space-between;

		padding: 3.6rem;

		width: 100%;
		min-height: 36rem;
		height: 50vh;
		max-height: 40rem;

		background: transparent;
		border: 2px solid ${props.theme.white}20;
		transition: ${props.theme.ease};

		${breakup.large`
            &:hover {
                background: ${props.theme.bc3};
                border: 2px solid ${props.theme.white}60;
				border-radius: 0 0 12rem 0;
            }
        `}
	`
);

export const Title = styled(H5)(
	(props) => css`
		//-
	`
);

export const Content = styled.ul(
	(props) => css`
		display: grid;
		grid-template-rows: repeat(3, 1fr);
		grid-gap: 0.6rem;
	`
);

export const Part = styled.li(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-start;
	`
);

export const Label = styled(P)(
	(props) => css`
		opacity: 0.6;
	`
);

export const Answer = styled(P)(
	(props) => css`
		margin: 0 0 0 1.2rem;
	`
);

export const Posted = styled(H6)(
	(props) => css`
		position: relative;
		padding-top: 2.4rem;
		margin-top: 2.4rem;

		&:before {
			${props.theme.grad(90, props.theme.bc1, props.theme.bc2)}
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 6rem;
			height: 1px;
		}
	`
);
