// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query CareersListing {
				careers: allDatoCmsCareersPost {
					nodes {
						id: originalId
						title: jobTitle
						position: positionType
						location
						salary
						link
						date: meta {
							published: publishedAt
						}
					}
				}
			}
		`
	);
	return data.careers;
};
