// Imports
// ------
import { useStaticQuery, graphql } from 'gatsby';

// Exports
// ------
export const useDato = () => {
	const data = useStaticQuery(
		graphql`
			query CareersHero {
				careers: datoCmsCareersPage {
					title
				}
			}
		`
	);
	return data.careers;
};
