// Imports
// ------
import React from 'react';
import Hero from './Hero';
import Listing from './Listing';
import CallToAction from '@parts/CallToAction';

// Component
// ------
function Careers() {
	return (
		<>
			<Hero />
			<Listing />
			<CallToAction />
		</>
	);
}

export default Careers;
